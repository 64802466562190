
import { Component, Vue } from "vue-property-decorator";

@Component({})

export default class Login extends Vue {
  private query = '';

  get currentUser() {
    return this.$store.state.currentUser
  }

  created() {
    if (window.location.search)
      this.query = window.location.search;

    if (this.currentUser) {
      this.$router.push({ path: '/' + this.query })
    }
  }

  submit() {
    const url = 'https://app.clickup.com/api' +
      `?client_id=${process.env.VUE_APP_CLICKUP_CLIENT_ID}` +
      `&redirect_uri=${window.location.origin}` + this.query;
    window.open(url, '_self');
  }
}
